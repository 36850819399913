"use client";

/* eslint-disable i18next/no-literal-string */
import { BiblioteksentralenLogoWithName } from "@biblioteksentralen/icons";
import { Box, Container, Flex, Heading, Link, Stack, Text, VStack } from "@biblioteksentralen/react";
import { imageUrlBuilder } from "@libry-content/common";
import { LinksToAllSites } from "../../components/LinksToAllSites";
import { SplashPageFooter } from "../../components/splashPage/SplashPageFooter";
import { SplashPageData } from "./page";

export const ClientSideSplashPage = (data: SplashPageData) => {
  return (
    <>
      <Header {...data} />
      <Sites {...data} />
      <SplashPageFooter />
    </>
  );
};

const Header = (props: SplashPageData) => (
  <Box
    as="section"
    minH={"110vh"}
    backgroundColor="blackAlpha.700"
    overflow="hidden"
    position="relative"
    isolation="isolate"
    color={"white"}
    background={`linear-gradient(rgba(0, 0, 0, 0.55),rgba(0, 0, 0, 0.65)), url("${imageUrlBuilder(
      props.splashPage?.image
    )
      ?.width(1200)
      .format("webp")
      .quality(80)
      .url()}")`}
    backgroundSize="cover"
    backgroundPosition="center" // FIXME: Background image that respects hotspot
  >
    <Flex
      flexDirection="column"
      justifyContent="center"
      position="relative"
      paddingY="max(10vmin, 3rem)"
      minH={"100vh"}
    >
      <Container maxW={`container.lg`} paddingX={{ base: "1rem", md: "2rem" }}>
        <VStack height="100%" paddingTop="5vh" textAlign="center">
          <Link href="https://www.bibsent.no/">
            <Box position="absolute" top="1rem" left="1rem" color="white" _hover={{ color: "#ddd" }}>
              <BiblioteksentralenLogoWithName aria-label="Biblioteksentralen" height="2.5em" />
            </Box>
          </Link>
          <Heading as="h1" fontSize={{ base: "4xl", md: "6xl" }} fontFamily="serif" lineHeight={1}>
            Libry Content
          </Heading>
          <Text fontSize={{ md: "lg" }}>Nettsider og formidlingsplattform skreddersydd for bibliotek</Text>
          <Link
            href="https://librycontent.no/"
            marginTop="1.5rem !important"
            variant="plain"
            backgroundColor="hsl(37deg 100% 72%)"
            _hover={{ backgroundColor: "hsl(37deg 100% 78%);" }}
            color="black"
            fontSize="xs"
            borderRadius="sm"
            padding=".75em 2em"
          >
            Les mer om Libry Content her
          </Link>
        </VStack>
      </Container>
    </Flex>
  </Box>
);

const Sites = (props: SplashPageData) => (
  <Box paddingY="6rem">
    <Container maxW="container.md">
      <Stack spacing="1rem" alignItems="flex-start">
        <Heading as="h2" size="md">
          Våre kunder
        </Heading>
        <Text>Besøk et av bibliotekene som bruker Libry Content i dag:</Text>
        <LinksToAllSites sites={props.sites} paddingTop="1rem" />
      </Stack>
    </Container>
  </Box>
);
